.p-m-index-about {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-about__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-about__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-about__head {
  display: flex;
}
.p-m-index-about__head img {
  width: calc(65.2 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-about__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-about__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-about__p01 .aux01 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-about__p01 .aux02 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-about__image {
  display: flex;
}
.p-m-index-about__image img {
  width: calc(50.5 * var(--rv));
  height: auto;
  object-fit: contain;
}


.p-m-index-about {
  padding-top: calc(8 * var(--rv));
  padding-bottom: calc(8 * var(--rv));
}
.p-m-index-about__top {
  align-items: center;
}
.p-m-index-about__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(7 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-about__bottom {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: calc(5 * var(--rv));
  padding-right: calc(5 * var(--rv));
}
.p-m-index-about__p01 {
  margin-bottom: calc(4 * var(--rv));
  line-height: 2.1;
  width: calc(42 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-about {
    position: relative;
  }
  .p-m-index-about__h3 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-about__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-about__head img {
    width: calc(52.7 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-about__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-about__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-about__p01 .aux02 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-about__image img {
    width: calc(57.7 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-about__h3 {
    margin-top: calc(-5.5 * var(--rv));
    margin-bottom: calc(5 * var(--rv));
    font-size: calc(3.8 * var(--rv));
  }
  .p-m-index-about__bottom {
    justify-content: center;
  }
  .p-m-index-about__p01 {
    letter-spacing: calc(-0.2 * var(--rv));
    line-height: 1.9;
    width: 100%;
  }
  .p-m-index-about__image {
    margin-bottom: calc(3 * var(--rv));
  }
}