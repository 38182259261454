.p-m-index-cta {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-cta__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-cta__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(6.115 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-cta__p02 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(6.65 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-cta__p02 .aux01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(6.65 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-cta__button {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(5.001 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-cta__illust {
  display: flex;
}
.p-m-index-cta__illust img {
  width: calc(139.1 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-cta__balloon {
  display: flex;
}
.p-m-index-cta__balloon img {
  width: calc(45.2 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-cta__image01 {
  display: flex;
}
.p-m-index-cta__image01 img {
  width: calc(7.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-cta__image02 {
  display: flex;
}
.p-m-index-cta__image02 img {
  width: calc(5.6 * var(--rv));
  height: auto;
  object-fit: contain;
}



.p-m-index-cta {
  overflow: hidden;
  background-color: #fbf1f8;
}
.p-m-index-cta__top {
  align-items: center;
}
.p-m-index-cta__p01 {
  margin-top: calc(8.5 * var(--rv));
  font-size: calc(4.8 * var(--rv));
}
.p-m-index-cta__p02 {
  letter-spacing: calc(-0.2 * var(--rv));
  text-align: center;
  margin-bottom: calc(3 * var(--rv));
  font-size: calc(5.65 * var(--rv));
  line-height: 1.2;
}
.p-m-index-cta__p02 .aux01 {
  font-size: calc(5.1 * var(--rv));
}
.p-m-index-cta__button {
  margin-bottom: calc(13 * var(--rv));
  font-size: calc(3.8 * var(--rv));
  width: calc(54 * var(--rv));
  height: calc(10 * var(--rv));
}
.p-m-index-cta__illust {
  position: absolute;
  top: calc(4 * var(--rv));
  left: calc(-15.5 * var(--rv));
}
.p-m-index-cta__balloon {
  margin-top: calc(-7 * var(--rv));
  margin-bottom: calc(1 * var(--rv));
}
.p-m-index-cta__image01 {
  position: absolute;
  top: calc(14 * var(--rv));
  left: calc(23 * var(--rv));
}
.p-m-index-cta__image02 {
  position: absolute;
  top: calc(12 * var(--rv));
  left: calc(79.5 * var(--rv));
}
@media (max-width: 750px) {
  .p-m-index-cta {
    position: relative;
  }
  .p-m-index-cta__p01 {
    font-size: calc(5.606 * var(--rv));
  }
  .p-m-index-cta__p02 {
    font-size: calc(8.36 * var(--rv));
  }
  .p-m-index-cta__p02 .aux01 {
    font-size: calc(8.36 * var(--rv));
  }
  .p-m-index-cta__button {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-cta__illust img {
    width: calc(93.8 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-cta__balloon img {
    width: calc(41.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-cta__p01 {
    margin-top: calc(13.5 * var(--rv));
  }
  .p-m-index-cta__p02 {
    margin-bottom: calc(4 * var(--rv));
    line-height: 1.1;
  }
  .p-m-index-cta__button {
    margin-bottom: calc(10 * var(--rv));
    width: calc(66 * var(--rv));
  }
  .p-m-index-cta__illust {
    left: calc(-9.5 * var(--rv));
  }
  .p-m-index-cta__balloon {
    margin-bottom: calc(0 * var(--rv));
  }
  .p-m-index-cta__image01 {
    display: none;
  }
  .p-m-index-cta__image02 {
    display: none;
    left: 0;
  }
}
@media (max-width: 750px) {
  .p-m-index-cta__p01 {
    font-size: calc(4.6 * var(--rv));
  }
  .p-m-index-cta__p02 {
    font-size: calc(6.5 * var(--rv));
  }
  .p-m-index-cta__p02 .aux01 {
    font-size: calc(4.8 * var(--rv));
  }
  .p-m-index-cta__button {
    font-size: calc(4 * var(--rv));
  }
}