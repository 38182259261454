/* ------------------------
 *   Foundation/Google Fonts
 * -------------------------------- */
/* ------------------------
 *   Foundation/Font Face
 * -------------------------------- */
/* ------------------------
 *   Foundation/Base
 * -------------------------------- */
/* Cumulative Layout Shift optimization */
/* for Tablet */
@media (min-width: 750px) {
  :root {
    --rv: calc(100vw / 108) !important;
  }
}
/* for PC (Scaling) */
/* for PC (Fixing) */
@media (min-width: 1080px) {
  :root {
    --rv: 10px !important;
  }
}
/* for PC (High resolution) */
@media (min-width: 1920px) {
  :root {
    --rv: calc(100vw / 192) !important;
  }
}
/* for Mobile */
@media (max-width: 750px) {
  :root {
    --rv: calc(100vw / 75) !important;
  }
}

.root-for-top {
  max-width: 100vw !important;
}

.top {
  /* overflow: hidden; */
  background-color: transparent;
  background-image: url('../../images/common/body_back.webp');
  background-position: center calc(-30 * var(--rv));
  background-repeat: repeat-y;
  background-size: calc(192 * var(--rv));
  margin: 0;
}

*[class*='-m-'],
*[class*='-m-']::before,
*[class*='-m-']::after {
  border-style: solid;
  border-width: 0;
  box-sizing: border-box;
}

*[class*='-m-'] {
  position: relative;
  margin: 0;
  padding: 0;
}

*[class*='-m-'] > picture {
  display: flex;
}

ul[class*='-m-'],
ol[class*='-m-'] {
  list-style: none;
}

a[class*='-m-'] {
  text-decoration: none;
}

*[class*='-m-'][class*='control'] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* End of base */
/* ------------------------
 *   Object/Component/Fade
 * -------------------------------- */
.is-m-hide {
  opacity: 0;
  transition: opacity 0.5s;
}

.c-m-fade-in--left {
  transform: translate(calc(5 * var(--rv)), 0) scale(1) rotate(0);
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-in--right {
  transform: translate(calc(-5 * var(--rv)), 0) scale(1) rotate(0);
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-in--down {
  transform: translate(0, calc(-5 * var(--rv))) scale(1) rotate(0);
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-in--up {
  transform: translate(0, calc(5 * var(--rv))) scale(1) rotate(0);
  transition: opacity 0.5s, transform 0.5s;
}

.c-m-fade-zoom--in {
  transform: translate(0, 0) scale(0) rotate(0);
  transform-origin: center;
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-zoom--out {
  transform: translate(0, 0) scale(2) rotate(0);
  transform-origin: center;
  transition: opacity 0.5s, transform 0.5s;
}

.c-m-fade-rotate--left {
  transform: translate(0, 0) scale(1) rotateZ(360deg);
  transform-origin: center;
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-rotate--right {
  transform: translate(0, 0) scale(1) rotateZ(-360deg);
  transform-origin: center;
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-rotate--in {
  transform: translate(0, 0) scale(0) rotateZ(360deg);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.c-m-fade-rotate--out {
  transform: translate(0, 0) scale(2) rotateZ(-360deg);
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}
.c-m-fade-rotate--x {
  transform: translate(0, 0) scale(1) rotateX(180deg);
  transform-origin: center;
  transition: opacity 0.5s, transform 0.5s;
}
.c-m-fade-rotate--y {
  transform: translate(0, 0) scale(1) rotateY(180deg);
  transform-origin: center;
  transition: opacity 0.5s, transform 0.5s;
}

.c-m-fade-delay01 {
  transition-delay: 0.25s;
}

.c-m-fade-delay02 {
  transition-delay: 0.5s;
}

.c-m-fade-delay03 {
  transition-delay: 0.75s;
}

.c-m-fade-delay04 {
  transition-delay: 1s;
}

.is-m-show {
  opacity: 1;
  transform: translate(0, 0) scale(1) rotate(0);
}

@media (max-width: 750px) {
  .c-m-fade-delay01 {
    transition-delay: 0s;
  }
  .c-m-fade-delay02 {
    transition-delay: 0s;
  }
  .c-m-fade-delay03 {
    transition-delay: 0s;
  }
  .c-m-fade-delay04 {
    transition-delay: 0s;
  }
}
/* ------------------------
 *   Object/Component/Inner
 * -------------------------------- */
.c-m-inner {
  max-width: calc(192 * var(--rv));
  margin: 0 auto;
}
.c-m-inner__content {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  min-width: calc(108 * var(--rv));
  max-width: calc(108 * var(--rv));
  margin: 0 auto;
}
.c-m-inner__content--expand {
  min-width: 100%;
  max-width: 100%;
}
.c-m-inner--wide {
  max-width: 100vw;
}

@media (max-width: 0px) {
  .c-m-inner {
    max-width: 100vw;
  }
  .c-m-inner__content {
    min-width: 100%;
    max-width: 100%;
  }
}
@media (max-width: 750px) {
  .c-m-inner {
    max-width: 100vw;
  }
  .c-m-inner__content {
    min-width: 100%;
    max-width: 100%;
  }
}
/* ------------------------
 *   Object/Component/Tabs
 * -------------------------------- */
/* ------------------------
 *   Object/Component/Card
 * -------------------------------- */
/* ------------------------
 *   Object/Component/List
 * -------------------------------- */
/* ------------------------
 *   Object/Component/Accordion
 * -------------------------------- */
.c-m-accordion {
  background-color: #ffffff;
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: calc(3 * var(--rv));
  width: 100%;
}
.c-m-accordion__block {
  width: inherit;
}
.c-m-accordion__label p {
  padding-right: calc(4 * var(--rv));
}
.c-m-accordion__label {
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}
.c-m-accordion__label::after {
  border-left: calc(0.3 * var(--rv)) solid #e82598;
  border-bottom: calc(0.3 * var(--rv)) solid #e82598;
  content: '';
  position: absolute;
  top: calc(50% - calc(1 * var(--rv)));
  right: calc(1.8 * var(--rv));
  transform: rotate(-45deg);
  transition: top 0.5s, transform 0.5s;
  width: calc(1 * var(--rv));
  height: calc(1 * var(--rv));
}
.c-m-accordion__folder {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  max-height: 0;
  margin-top: 0;
  opacity: 0;
  overflow: hidden;
  transition: all 0.5s;
  width: 100%;
}
.c-m-accordion p {
  padding-left: calc(1 * var(--rv));
}

.c-m-accordion input[id^='q']:checked ~ .c-m-accordion__label::after {
  top: calc(50% - calc(0.6 * var(--rv)));
  transform: rotate(135deg);
}
.c-m-accordion input[id^='q']:checked ~ .c-m-accordion__folder {
  border-top: calc(0.1 * var(--rv)) solid #e6e6e6;
  max-height: calc(9 * var(--rv));
  margin-top: calc(0.5 * var(--rv));
  padding-top: calc(1.5 * var(--rv));
  opacity: 1;
}

@media (max-width: 750px) {
  .c-m-accordion {
    padding-bottom: calc(6 * var(--rv));
  }
  .c-m-accordion__label p {
    padding-right: calc(6 * var(--rv));
  }
  .c-m-accordion__label::after {
    border-left: calc(0.8 * var(--rv)) solid #e82598;
    border-bottom: calc(0.8 * var(--rv)) solid #e82598;
    width: calc(2.5 * var(--rv));
    height: calc(2.5 * var(--rv));
  }
  .c-m-accordion input[id^='q']:checked ~ .c-m-accordion__folder {
    max-height: calc(50 * var(--rv));
    margin-top: calc(2.5 * var(--rv));
    padding-top: calc(2.5 * var(--rv));
  }
}
/* ------------------------
 *   Object/Utility/Browser
 * -------------------------------- */
.u-m-browser-ie {
  position: relative;
}

.u-m-browser-edge {
  position: relative;
}

.u-m-browser-chrome {
  position: relative;
}

.u-m-browser-firefox {
  position: relative;
}

.u-m-browser-safari {
  position: relative;
}

/* ------------------------
 *   Object/Utility/Only
 * -------------------------------- */
.u-m-only-pc {
  display: block;
}

.u-m-only-sp {
  display: none;
}

.u-m-only-pc--flex {
  display: flex;
}

.u-m-only-sp--flex {
  display: none;
}

.u-m-only-pc--inline {
  display: inline;
}

.u-m-only-sp--inline {
  display: none;
}

.u-m-only-pc--inline-block {
  display: inline-block;
}

.u-m-only-sp--inline-block {
  display: none;
}

.u-m-only-pc--inline-flex {
  display: inline-flex;
}

.u-m-only-sp--inline-flex {
  display: none;
}

@media (max-width: 750px) {
  .u-m-nobr {
    display: none; /* Disable line breaks. And wrap the content. */
  }
  .u-m-only-pc {
    display: none;
  }
  .u-m-only-sp {
    display: block;
  }
  .u-m-only-pc--flex {
    display: none;
  }
  .u-m-only-sp--flex {
    display: flex;
  }
  .u-m-only-pc--inline {
    display: none;
  }
  .u-m-only-sp--inline {
    display: inline;
  }
  .u-m-only-pc--inline-block {
    display: none;
  }
  .u-m-only-sp--inline-block {
    display: inline-block;
  }
  .u-m-only-pc--inline-flex {
    display: none;
  }
  .u-m-only-sp--inline-flex {
    display: inline-flex;
  }
}
/* ------------------------
 *   Object/Utility/Platform
 * -------------------------------- */
.u-m-platform-windows {
  position: relative;
}

.u-m-platform-android {
  position: relative;
}

.u-m-platform-ios {
  position: relative;
}

.u-m-platform-macintosh {
  position: relative;
}

/* ------------------------
 *   Object/Utility/Deco
 * -------------------------------- */
.u-m-deco--grad {
  background: linear-gradient(90deg, rgb(255, 90, 146) 0%, rgb(180, 110, 255) 100%);
  border-radius: calc(4 * var(--rv));
  padding: calc(0.2 * var(--rv)) calc(2 * var(--rv));
}

@media (max-width: 750px) {
  .u-m-deco--grad {
    padding: calc(0.5 * var(--rv)) calc(2.5 * var(--rv));
  }
}
/* ------------------------
 *   Object/Utility/Container
 * -------------------------------- */
.u-m-container--shadow {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: calc(3 * var(--rv));
  width: calc(30.5 * var(--rv));
  height: calc(47 * var(--rv));
}

.u-m-container-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

@media (max-width: 750px) {
  .u-m-container--shadow {
    padding-top: calc(6 * var(--rv));
    padding-bottom: calc(5 * var(--rv));
    margin-bottom: calc(4.5 * var(--rv));
    width: 100%;
    height: auto;
  }
}
/* ------------------------
 *   Object/Utility/Back
 * -------------------------------- */
.u-m-back--user {
  background-color: #636363;
  border-radius: calc(2 * var(--rv));
  text-align: center;
  padding: calc(0.5 * var(--rv)) 0;
  width: calc(16 * var(--rv));
}
.u-m-back--player {
  background-color: #ea3fa0;
  border-radius: calc(2 * var(--rv));
  text-align: center;
  padding: calc(0.5 * var(--rv)) 0;
  width: calc(16 * var(--rv));
}

@media (max-width: 750px) {
  .u-m-back--user {
    border-radius: calc(3 * var(--rv));
    width: calc(29 * var(--rv));
  }
  .u-m-back--player {
    border-radius: calc(3 * var(--rv));
    width: calc(29 * var(--rv));
  }
}
/* ------------------------
 *   Object/Utility/Button
 * -------------------------------- */
.u-m-button {
  background: linear-gradient(90deg, rgb(249, 50, 139) 0%, rgb(197, 57, 178) 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
}
.u-m-button--grad {
  background: linear-gradient(90deg, rgb(249, 50, 139) 0%, rgb(197, 57, 178) 100%);
  border-radius: calc(4 * var(--rv));
  box-shadow: 0 calc(0.3 * var(--rv)) 0 #fcc8dd;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}
.u-m-button--grad:hover {
  opacity: 0.7;
}
.u-m-button--gradation {
  background: linear-gradient(90deg, rgb(249, 50, 139) 0%, rgb(197, 57, 178) 100%);
  border-radius: calc(5 * var(--rv));
  box-shadow: 0 calc(1 * var(--rv)) 0 #fcc8dd;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}
.u-m-button--gradation:hover {
  opacity: 0.7;
}
.u-m-button--white {
  background-color: #ffffff;
  border: calc(0.2 * var(--rv)) solid #e82598;
  border-radius: calc(4 * var(--rv));
  box-shadow: 0 calc(0.3 * var(--rv)) 0 #fcc8dd;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.3s;
}
.u-m-button--white:hover {
  opacity: 0.7;
}

.u-m-button:hover {
  -webkit-text-fill-color: #ffffff;
}

.u-m-button:hover::after {
  opacity: 0;
}

.u-m-button::before {
  background: linear-gradient(90deg, rgb(249, 50, 139) 0%, rgb(197, 57, 178) 100%);
  border-radius: calc(4 * var(--rv));
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
}

.u-m-button::after {
  background-color: #ffffff;
  border-radius: calc(4 * var(--rv));
  content: '';
  position: absolute;
  top: calc(0.4 * var(--rv));
  left: calc(0.4 * var(--rv));
  z-index: -1;
  transition: opacity 0.3s;
  width: calc(100% - calc(0.8 * var(--rv)));
  height: calc(100% - calc(0.8 * var(--rv)));
}

@media (max-width: 750px) {
  .u-m-button--grad {
    box-shadow: 0 calc(0.5 * var(--rv)) 0 #fcc8dd;
  }
  .u-m-button--white {
    box-shadow: 0 calc(0.5 * var(--rv)) 0 #fcc8dd;
  }
  .u-m-button--gradation {
    border-radius: calc(5 * var(--rv));
  }
  .u-m-button::before {
    border-radius: calc(5 * var(--rv));
  }
  .u-m-button::after {
    border-radius: calc(5 * var(--rv));
  }
}
