.c-m-card--flow {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: calc(44 * var(--rv));
  padding: calc(3 * var(--rv));
  width: calc(28.5 * var(--rv));
}
.c-m-card--flow::after {
  background-image: url(../../images/top/flow_tri@2x.webp);
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  content: "";
  position: absolute;
  top: calc(50% - calc(1 * var(--rv)));
  right: calc(-4.5 * var(--rv));
  width: calc(2.5 * var(--rv));
  height: calc(4.3 * var(--rv));
}
.c-m-card--flow:nth-last-of-type(1)::after {
  display: none;
}
.p-m-index-flow {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-flow__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-flow__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__p01 .aux01 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__head {
  display: flex;
}
.p-m-index-flow__head img {
  width: calc(51.3 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-flow__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-flow__h4 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__p02 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.487 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__span {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.575 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__p02 .aux01 {
  color: #ea3fa0;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.487 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-flow__icon {
  display: flex;
}
.p-m-index-flow__icon img {
  width: calc(13.5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-flow__tri {
  display: flex;
}
.p-m-index-flow__tri img {
  width: calc(2.5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-flow {
  padding-top: calc(6 * var(--rv));
  padding-bottom: calc(3 * var(--rv));
}
.p-m-index-flow__top {
  align-items: center;
}
.p-m-index-flow__bottom {
  flex-direction: row;
  justify-content: space-between;
  padding-left: calc(5 * var(--rv));
  padding-right: calc(5 * var(--rv));
}
.p-m-index-flow__h4 {
  text-align: center;
  margin-bottom: calc(1 * var(--rv));
  line-height: 1.7;
}
.p-m-index-flow__p02 {
  line-height: 1.75;
}
.p-m-index-flow__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(7 * var(--rv));
}
.p-m-index-flow__p01 {
  margin-bottom: calc(4 * var(--rv));
}
.p-m-index-flow__card {
  margin-bottom: calc(3.5 * var(--rv));
}
.p-m-index-flow__span {
  margin-bottom: calc(1.5 * var(--rv));
}
.p-m-index-flow__icon {
  margin-bottom: calc(2 * var(--rv));
}


@media (max-width: 750px) {
  .c-m-card--flow {
    padding: calc(5 * var(--rv)) calc(3 * var(--rv));
    width: 100%;
  }
  .c-m-card--flow::after {
    top: auto;
    right: calc(50% - calc(1.7 * var(--rv)));
    bottom: calc(-6.5 * var(--rv));
    transform: rotate(90deg);
    width: calc(3.5 * var(--rv));
    height: calc(5.3 * var(--rv));
  }

  .p-m-index-flow {
    position: relative;
  }
  .p-m-index-flow__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-flow__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-flow__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-flow__head img {
    width: calc(41.6 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-flow__h4 {
    font-size: calc(4.5 * var(--rv));
  }
  .p-m-index-flow__p02 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-flow__span {
    font-size: calc(2.8 * var(--rv));
  }
  .p-m-index-flow__p02 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-flow__icon img {
    width: calc(20.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-flow {
    padding-top: calc(5 * var(--rv));
  }
  .p-m-index-flow__h3 {
    margin-top: calc(-5 * var(--rv));
    margin-bottom: calc(4 * var(--rv));
  }
  .p-m-index-flow__card {
    margin-bottom: calc(7.5 * var(--rv));
  }
  .p-m-index-flow__p02 {
    letter-spacing: calc(-0.2 * var(--rv));
  }
}