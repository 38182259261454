.p-m-index-entry {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  background-image: url(../../images/top/entry_back@2x.webp);
  background-position: top;
  background-repeat: no-repeat;
  background-size: calc(192 * var(--rv));
  min-height: calc(74.6 * var(--rv));
}
.p-m-index-entry__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-entry__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__p01 .aux01 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__h4 {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(2.975 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__p02 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.4 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__p03 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.4 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__button {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(4.637 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-entry__line {
  display: flex;
}
.p-m-index-entry__line img {
  width: calc(10.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-entry__head {
  display: flex;
}
.p-m-index-entry__head img {
  width: calc(59.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-entry {
  padding-top: calc(9 * var(--rv));
}
.p-m-index-entry__top {
  align-items: center;
}
.p-m-index-entry__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(7 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-entry__button {
  margin-bottom: calc(5 * var(--rv));
  font-size: calc(3.5 * var(--rv));
  width: calc(56 * var(--rv));
  height: calc(9 * var(--rv));
}
.p-m-index-entry__p01 {
  margin-bottom: calc(3 * var(--rv));
}
.p-m-index-entry__container {
  align-items: center;
  margin-bottom: calc(3 * var(--rv));
}
.p-m-index-entry__p02 {
  margin-bottom: calc(5 * var(--rv));
}
.p-m-index-entry__line {
  margin-right: calc(3 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-entry {
    position: relative;
    background-image: url(../../images/top/entry_back_sp.webp);
    background-size: calc(75 * var(--rv));
    min-height: calc(120.3 * var(--rv));
  }
  .p-m-index-entry__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-entry__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-entry__h4 {
    font-size: calc(3.4 * var(--rv));
  }
  .p-m-index-entry__p02 {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-entry__p03 {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-entry__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-entry__button {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-entry__line img {
    width: calc(22.5 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-entry__head img {
    width: calc(48.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-entry {
    padding-top: calc(10 * var(--rv));
  }
  .p-m-index-entry__p01 {
    text-align: center;
    line-height: 1.8;
  }
  .p-m-index-entry__p02 {
    margin-bottom: calc(3 * var(--rv));
    text-align: center;
  }
  .p-m-index-entry__h3 {
    margin-top: calc(-5 * var(--rv));
    margin-bottom: calc(5 * var(--rv));
  }
  .p-m-index-entry__button {
    margin-bottom: calc(3 * var(--rv));
    width: calc(66 * var(--rv));
    height: calc(10 * var(--rv));
  }
  .p-m-index-entry__container {
    margin-bottom: calc(6 * var(--rv));
    flex-direction: column;
  }
  .p-m-index-entry__line {
    margin-bottom: calc(4 * var(--rv));
  }
  .p-m-index-entry__button {
    font-size: calc(4 * var(--rv));
  }
}