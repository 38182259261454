
.p-m-index-addition {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-addition__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-addition__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-addition__head {
  display: flex;
}
.p-m-index-addition__head img {
  width: calc(90.6 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-addition__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-addition__h4 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-addition__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-addition__icon {
  display: flex;
}
.p-m-index-addition__icon img {
  width: calc(13.5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-addition {
  padding-top: calc(6 * var(--rv));
  padding-bottom: calc(6 * var(--rv));
}
.p-m-index-addition__top {
  align-items: center;
}
.p-m-index-addition__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(8 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-addition__bottom {
  flex-direction: row;
  justify-content: space-between;
  padding-left: calc(5 * var(--rv));
  padding-right: calc(5 * var(--rv));
}
.p-m-index-addition__h4 {
  text-align: center;
  margin-bottom: calc(1 * var(--rv));
  line-height: 1.8;
}
.p-m-index-addition__p01 {
  line-height: 1.8;
}
.p-m-index-addition__icon {
  margin-bottom: calc(2 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-addition {
    position: relative;
  }
  .p-m-index-addition__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-addition__head img {
    width: calc(73.3 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-addition__h4 {
    font-size: calc(4.5 * var(--rv));
  }
  .p-m-index-addition__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-addition__icon img {
    width: calc(23.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-addition__h3 {
    margin-top: calc(-4 * var(--rv));
    margin-bottom: calc(5 * var(--rv));
  }
  .p-m-index-addition__icon {
    margin-bottom: calc(2.5 * var(--rv));
  }
  .p-m-index-addition__h3 {
    font-size: calc(5 * var(--rv));
  }
}