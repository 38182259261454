.p-m-index-suggest {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-suggest__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-suggest__h3 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-suggest__p01 {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-suggest__p01 .aux01 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-suggest__p01 .aux02 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-suggest__head {
  display: flex;
}
.p-m-index-suggest__head img {
  width: calc(86.7 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-suggest__center {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-suggest__figcaption {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.75 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-suggest__player {
  display: flex;
  width: 20%;
}
.p-m-index-suggest__player > div {
  width: 100% !important;
}
.p-m-index-suggest__player > img {
  width: calc(20.1 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-suggest__play {
  display: flex;
}
.p-m-index-suggest__play img {
  width: calc(5.2 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-suggest__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-suggest__button {
  color: #e82598;
  -webkit-text-fill-color: #e82598;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(3.78 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-suggest {
  padding-top: calc(5 * var(--rv));
  padding-bottom: calc(6 * var(--rv));
}
.p-m-index-suggest__top {
  align-items: center;
}
.p-m-index-suggest__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(5.5 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-suggest__p01 {
  text-align: center;
  line-height: 2;
}
.p-m-index-suggest__center {
  flex-direction: row;
  justify-content: space-between;
  padding-left: calc(5 * var(--rv)) !important;
  padding-right: calc(5 * var(--rv)) !important;
  padding: calc(5 * var(--rv));
}
.p-m-index-suggest__player > img {
  border-radius: 100%;
  width: calc(18.7 * var(--rv));
  height: calc(18.7 * var(--rv));
}
.p-m-index-suggest__bottom {
  align-items: center;
}
.p-m-index-suggest__button {
  margin-top: calc(2 * var(--rv));
  font-size: calc(2.8 * var(--rv));
  width: calc(38 * var(--rv));
  height: calc(7.5 * var(--rv));
}
.p-m-index-suggest__figcaption {
  text-align: center;
  margin-top: calc(1.5 * var(--rv));
  margin-bottom: calc(4 * var(--rv));
}
.p-m-index-suggest__player {
  flex-direction: column;
  margin: 0 calc(1 * var(--rv));
  z-index: 1;
}
/* .p-m-index-suggest__player::before {
  background: linear-gradient(135deg, rgb(222, 237, 248) 0%, rgb(252, 226, 243) 100%);
  border-radius: 100%;
  content: "";
  position: absolute;
  top: calc(-0.7 * var(--rv));
  left: calc(-0.7 * var(--rv));
  z-index: -1;
  width: calc(20.1 * var(--rv));
  height: calc(20.1 * var(--rv));
} */
/* .p-m-index-suggest__player.is-m-dummy {
  margin: 0;
  width: calc(20.1 * var(--rv));
  height: 0;
}
.p-m-index-suggest__player.is-m-dummy::before {
  display: none;
} */
.p-m-index-suggest__play {
  position: absolute;
  top: calc(13.5 * var(--rv));
  right: 0;
}
.p-m-index-suggest__play--sample {
  display: flex;
  top: calc(-4 * var(--rv));
  left: calc(-16 * var(--rv));
}
.p-m-index-suggest__play--sample img {
  width: calc(4.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
@media (max-width: 750px) {
  .p-m-index-suggest {
    position: relative;
  }
  .p-m-index-suggest__h3 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-suggest__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-suggest__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-suggest__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-suggest__p01 .aux02 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-suggest__head img {
    width: calc(70.1 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-suggest__figcaption {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-suggest__button {
    font-size: calc(4.2 * var(--rv));
  }
  .p-m-index-suggest__button {
    font-size: calc(3.2 * var(--rv));
  }
  .p-m-index-suggest {
    padding-top: calc(4 * var(--rv));
    padding-bottom: calc(5 * var(--rv));
  }
  .p-m-index-suggest__h3 {
    margin-top: calc(-5 * var(--rv));
    margin-bottom: calc(3.5 * var(--rv));
  }
  .p-m-index-suggest__p01 {
    text-align: left;
    letter-spacing: calc(-0.1 * var(--rv));
    padding: 0 calc(3.5 * var(--rv));
    line-height: 1.85;
  }
  .p-m-index-suggest__figcaption {
    margin-top: calc(2 * var(--rv));
    margin-bottom: calc(7 * var(--rv));
  }
  .p-m-index-suggest__player {
    display: flex;
    width: 45%;
  }
  .p-m-index-suggest__player > img {
    width: calc(25.6 * var(--rv));
    height: calc(25.6 * var(--rv));
  }
  .p-m-index-suggest__play {
    top: calc(18.2 * var(--rv));
    right: calc(-1 * var(--rv));
  }
  .p-m-index-suggest__play--sample {
    top: calc(-5 * var(--rv));
    left: calc(-30.5 * var(--rv));
  }
  .p-m-index-suggest__play img {
    width: calc(7.2 * var(--rv));
    height: calc(7.2 * var(--rv));
  }
  .p-m-index-suggest__button {
    margin-top: calc(-1.5 * var(--rv));
    width: calc(66 * var(--rv));
    height: calc(9.5 * var(--rv));
  }
  .p-m-index-suggest__player::before {
    top: calc(-1 * var(--rv));
    left: calc(-1 * var(--rv));
    width: calc(27.6 * var(--rv));
    height: calc(27.6 * var(--rv));
  }
}
