/* ------------------------
 *   Layout/Header
 * -------------------------------- */
.l-m-header {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.l-m-header--fixed {
  position: fixed;
  top: 0;
  left: 0;
}
.l-m-header__left {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.l-m-header__logo {
  display: flex;
}
.l-m-header__logo img {
  width: calc(9.3 * var(--rv));
  height: auto;
  object-fit: contain;
}
.l-m-header__right {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.l-m-header__line {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(1.89 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.l-m-header__login {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(1.89 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

/* Add */
.l-m-header__left {
  justify-content: center;
  max-width: 50%;
  padding-left: calc(5 * var(--rv));
  height: calc(7 * var(--rv));
}
.l-m-header__right {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  max-width: 50%;
  padding-right: calc(5 * var(--rv));
  height: calc(7 * var(--rv));
}
.l-m-header__line {
  font-size: calc(1.6 * var(--rv));
  width: calc(14.5 * var(--rv));
  height: calc(3.5 * var(--rv));
}
.l-m-header__login {
  margin-right: calc(1 * var(--rv));
  font-size: calc(1.6 * var(--rv));
  width: calc(14.5 * var(--rv));
  height: calc(3.5 * var(--rv));
}

@media (max-width: 750px) {
  .l-m-header {
    position: relative;
  }
  .l-m-header__logo img {
    width: calc(13.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .l-m-header__login {
    font-size: calc(3 * var(--rv));
  }
  /* Add */
  .l-m-header__left {
    max-width: 30%;
    padding-left: calc(3.5 * var(--rv));
    height: calc(9 * var(--rv));
  }
  .l-m-header__right {
    max-width: 70%;
    padding-right: calc(3.5 * var(--rv));
    height: calc(9 * var(--rv));
  }
  .l-m-header__line {
    font-size: calc(2.2 * var(--rv));
    width: calc(21.5 * var(--rv));
    height: calc(6 * var(--rv));
  }
  .l-m-header__login {
    margin-right: calc(2.5 * var(--rv));
    font-size: calc(2.2 * var(--rv));
    width: calc(21.5 * var(--rv));
    height: calc(6 * var(--rv));
  }
}