/* ------------------------
 *   Layout/Footer
 * -------------------------------- */
.l-m-footer {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.l-m-footer__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.l-m-footer__li {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.662 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.l-m-footer__logo {
  display: flex;
}
.l-m-footer__logo img {
  width: calc(9.3 * var(--rv));
  height: auto;
  object-fit: contain;
}
.l-m-footer__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.l-m-footer__copyright {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Regular", "Noto Sans JP", sans-serif;
  font-size: calc(1.05 * var(--rv));
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-line;
}

/* Add */
.l-m-footer {
  padding-top: calc(3.5 * var(--rv));
  background-color: #f784c8;
}
.l-m-footer__top {
  align-items: center;
}
.l-m-footer__bottom {
  align-items: center;
}
.l-m-footer__list {
  display: flex;
  justify-content: center;
  margin: calc(0.8 * var(--rv)) 0;
}
.l-m-footer__li {
  margin: 0 calc(2 * var(--rv));
}
.l-m-footer__li a {
  text-decoration: none;
  color: inherit;
}
.l-m-footer__logo {
  margin-bottom: calc(1.5 * var(--rv));
}
.l-m-footer__copyright {
  margin-top: calc(4.5 * var(--rv));
  margin-bottom: calc(3 * var(--rv));
}

@media (max-width: 750px) {
  .l-m-footer {
    position: relative;
  }
  .l-m-footer__li {
    font-size: calc(3 * var(--rv));
  }
  .l-m-footer__logo img {
    width: calc(27 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .l-m-footer__copyright {
    font-size: calc(2.4 * var(--rv));
  }
  /* Add */
  .l-m-footer {
    padding-top: calc(8.5 * var(--rv));
  }
  .l-m-footer__logo {
    margin-bottom: calc(7.5 * var(--rv));
  }
  .l-m-footer__copyright {
    margin-top: calc(10.5 * var(--rv));
    margin-bottom: calc(5 * var(--rv));
  }
  .l-m-footer__li {
    margin-bottom: calc(2.5 * var(--rv));
  }
  .l-m-footer__list {
    flex-direction: column;
    align-items: center;
  }
}