.p-m-index-game {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.p-m-index-game__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.p-m-index-game__h3 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-game__p01 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(2.8 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-game__head {
  display: flex;
}

.p-m-index-game__head img {
  width: calc(52.1 * var(--rv));
  height: auto;
  object-fit: contain;
}

.p-m-index-game__balloon {
  display: flex;
}

.p-m-index-game__balloon img {
  width: calc(98.8 * var(--rv));
  height: auto;
  object-fit: contain;
}

.p-m-index-game__center {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.p-m-index-game__figcaption {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.75 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-game__icon {
  display: flex;
}

.p-m-index-game__icon img {
  width: calc(18 * var(--rv));
  height: auto;
  object-fit: contain;
  border-radius: 16px;
}

.p-m-index-game__middle {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.4 * var(--rv));
  font-weight: 700;
  line-height: 1.75;
  white-space: pre-line;
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
  margin-bottom: 16px;
}

.p-m-index-game__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.p-m-index-game__button {
  color: #e82598;
  -webkit-text-fill-color: #e82598;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(3.78 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-game {
  padding-top: calc(3.5 * var(--rv));
  padding-bottom: calc(12 * var(--rv));
}

.p-m-index-game__top {
  align-items: center;
}

.p-m-index-game__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(6.5 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}

.p-m-index-game__center {
  flex-direction: row;
  justify-content: space-between;
  padding-left: calc(3 * var(--rv));
  padding-right: calc(3 * var(--rv));
}

.p-m-index-game__middle {
  flex-direction: row;
  justify-content: space-between;
  padding-left: calc(3 * var(--rv));
  padding-right: calc(3 * var(--rv));
}

.p-m-index-game__bottom {
  align-items: center;
}

.p-m-index-game__button {
  margin-top: calc(3.5 * var(--rv));
  font-size: calc(2.8 * var(--rv));
  width: calc(39 * var(--rv));
  height: calc(7.5 * var(--rv));
}

.p-m-index-game__p01 strong {
  font-size: calc(4.2 * var(--rv));
}

.p-m-index-game__balloon {
  margin-top: calc(-5.5 * var(--rv));
  margin-bottom: calc(9 * var(--rv));
}

.p-m-index-game__figcaption {
  text-align: center;
  margin-top: calc(1 * var(--rv));
  margin-bottom: calc(3 * var(--rv));
}

.p-m-index-game__icon {
  flex-direction: column;
  margin: 0 calc(2 * var(--rv));
}

.p-m-index-game__icon.is-m-dummy {
  margin: 0;
  width: calc(18 * var(--rv));
  height: 0;
}

@media (max-width: 750px) {
  .p-m-index-game {
    position: relative;
  }

  .p-m-index-game__h3 {
    font-size: calc(5.5 * var(--rv));
  }

  .p-m-index-game__p01 {
    font-size: calc(3.315 * var(--rv));
  }

  .p-m-index-game__head img {
    width: calc(42 * var(--rv));
    height: auto;
    object-fit: contain;
  }

  .p-m-index-game__figcaption {
    font-size: calc(2.4 * var(--rv));
  }

  .p-m-index-game__button {
    font-size: calc(4.2 * var(--rv));
  }

  .p-m-index-game {
    padding-top: calc(6.5 * var(--rv));
  }

  .p-m-index-game__h3 {
    margin-top: calc(-4.5 * var(--rv));
    margin-bottom: calc(3.5 * var(--rv));
  }

  .p-m-index-game__balloon {
    margin-top: calc(-10.5 * var(--rv));
  }

  .p-m-index-game__balloon img {
    width: calc(65.8 * var(--rv));
    height: calc(8.3 * var(--rv));
  }

  .p-m-index-game__center {
    padding-left: calc(6 * var(--rv));
    padding-right: calc(6 * var(--rv));
  }

  .p-m-index-game__middle {
    font-size: calc(2.4 * var(--rv));
    padding: 0 calc(5 * var(--rv));
  }

  .p-m-index-game__figcaption {
    margin-top: calc(1.5 * var(--rv));
    margin-bottom: calc(6 * var(--rv));
  }

  .p-m-index-game__icon {
    margin: 0;
  }

  .p-m-index-game__icon img {
    width: calc(26 * var(--rv));
    height: calc(26.1 * var(--rv));
  }

  .p-m-index-game__button {
    margin-top: calc(1 * var(--rv));
    width: calc(58 * var(--rv));
    height: calc(9.5 * var(--rv));
  }

  .p-m-index-game__p01 {
    text-align: center;
  }

  .p-m-index-game__h3 {
    font-size: calc(5 * var(--rv));
  }

  .p-m-index-game__button {
    font-size: calc(3.2 * var(--rv));
  }
}
