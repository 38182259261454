.c-m-list--price {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: flex;
  overflow: hidden;
}
.c-m-list--price li:nth-child(1) {
  background: linear-gradient(90deg, #cfb6e5 0%, #e1bce0 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(17.5 * var(--rv));
  height: calc(9 * var(--rv));
}
.c-m-list--price li:nth-child(2) {
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(28.8 * var(--rv));
  height: calc(9 * var(--rv));
}

.p-m-index-price {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-price__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-price__h3 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-price__p01 {
  color: #333333;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-price__p01 .aux01 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-price__head {
  display: flex;
}
.p-m-index-price__head img {
  width: calc(52.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-price__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-price__desc {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(3.588 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-price__term {
  color: #ffffff;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-price__p02 {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.4 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-price {
  padding-top: calc(13 * var(--rv));
  padding-bottom: calc(6 * var(--rv));
}
.p-m-index-price__top {
  align-items: center;
}
.p-m-index-price__p01 {
  text-align: center;
  margin-bottom: calc(3.5 * var(--rv));
  line-height: 2;
}
.p-m-index-price__bottom {
  align-items: center;
}
.p-m-index-price__desc {
  font-size: calc(4.8 * var(--rv));
}
.p-m-index-price__desc small {
  font-size: 70%;
  position: relative;
  top: calc(0.5 * var(--rv));
}
.p-m-index-price__p02 {
  line-height: 1.75;
}
.p-m-index-price__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(8.5 * var(--rv));
}
.p-m-index-price__term strong {
  font-size: calc(2.6 * var(--rv));
}

@media (max-width: 750px) {
  .c-m-list--price li:nth-child(1) {
    width: calc(25.5 * var(--rv));
    height: calc(12 * var(--rv));
  }
  .c-m-list--price li:nth-child(2) {
    width: calc(40.8 * var(--rv));
    height: calc(12 * var(--rv));
  }
  .p-m-index-price {
    position: relative;
  }
  .p-m-index-price__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-price__head img {
    width: calc(42.8 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-price__desc {
    font-size: calc(5.082 * var(--rv));
  }
  .p-m-index-price__term {
    font-size: calc(2.975 * var(--rv));
  }
  .p-m-index-price {
    padding-top: calc(12 * var(--rv));
  }
  .p-m-index-price__h3 {
    margin-top: calc(-5 * var(--rv));
    margin-bottom: calc(4.5 * var(--rv));
  }
  .p-m-index-price__p01 {
    font-size: calc(2.8 * var(--rv));
  }
  .p-m-index-price__p01 .aux01 {
    font-size: calc(2.8 * var(--rv));
  }
  .p-m-index-price__p02 {
    font-size: calc(2.4 * var(--rv));
    padding: 0 calc(5 * var(--rv));
  }
  .p-m-index-price__term strong {
    font-size: calc(3.6 * var(--rv));
  }
  .p-m-index-price__h3 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-price__desc {
    font-size: calc(6.682 * var(--rv));
  }
}
