.p-m-index-review {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-review__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-review__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__p01 .aux01 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__head {
  display: flex;
}
.p-m-index-review__head img {
  width: calc(70.5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-review__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-review__span {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(1.75 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__name02 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.225 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__point {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__comment {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__name01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.75 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__time {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(1.313 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-review__reviewer {
  display: flex;
}
.p-m-index-review__reviewer img {
  width: calc(9.8 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-review__player {
  display: flex;
}
.p-m-index-review__player img {
  width: calc(10.1 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-review {
  padding-top: calc(6 * var(--rv));
  padding-bottom: calc(6 * var(--rv));
}
.p-m-index-review__top {
  align-items: center;
}
.p-m-index-review__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(7 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-review__p01 {
  margin-bottom: calc(4 * var(--rv));
  font-size: calc(1.9 * var(--rv));
}
.p-m-index-review__span {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: calc(3.2 * var(--rv));
  font-size: calc(1.4 * var(--rv));
  width: calc(25 * var(--rv));
}
.p-m-index-review__card {
  margin-top: calc(1.5 * var(--rv));
  margin-bottom: calc(1.5 * var(--rv));
}
.p-m-index-review__span::after {
  border-style: solid;
  border-width: calc(0.8 * var(--rv)) 0 calc(0.8 * var(--rv)) calc(1.2 * var(--rv));
  border-color: transparent transparent transparent #ee88c5;
  content: "";
  position: absolute;
  top: calc(2.8 * var(--rv));
  left: calc(6.2 * var(--rv));
  z-index: 1;
  width: 0;
  height: 0;
}
.p-m-index-review__name02 {
  text-align: center;
  margin-top: calc(1 * var(--rv));
}
.p-m-index-review__star {
  display: flex;
  flex-direction: row;
  max-width: calc(17.3 * var(--rv));
  margin-top: calc(1 * var(--rv));
  margin-bottom: calc(0.8 * var(--rv));
}
.p-m-index-review__star img {
  border-radius: 0;
  width: calc(8.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-review__point {
  margin-left: calc(0.5 * var(--rv));
}
.p-m-index-review__time {
  background-color: #e82598;
  border-radius: calc(2 * var(--rv));
  margin-left: calc(1.2 * var(--rv));
  padding: calc(0.2 * var(--rv)) calc(1 * var(--rv));
}
.p-m-index-review__reviewer {
  margin-right: calc(3 * var(--rv));
}
.p-m-index-review__reviewer img {
  border-radius: 100%;
}
.p-m-index-review__reviewer::after {
  background: #eeeeee;
  border-radius: 100%;
  content: "";
  position: absolute;
  top: calc(-0.5 * var(--rv));
  left: calc(-0.5 * var(--rv));
  z-index: -1;
  width: calc(10.8 * var(--rv));
  height: calc(10.9 * var(--rv));
}
.p-m-index-review__player {
  flex-direction: column;
}
.p-m-index-review__player img {
  border-radius: 100%;
}
.p-m-index-review__player::before {
  background: linear-gradient(135deg, rgb(222, 237, 248) 0%, rgb(252, 226, 243) 100%);
  border-radius: 100%;
  content: "";
  position: absolute;
  top: calc(-0.5 * var(--rv));
  left: calc(-0.5 * var(--rv));
  z-index: -1;
  width: calc(11.1 * var(--rv));
  height: calc(11.1 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-review {
    position: relative;
  }
  .p-m-index-review__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-review__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-review__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-review__head img {
    width: calc(57.1 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-review__span {
    font-size: calc(2.625 * var(--rv));
  }
  .p-m-index-review__name02 {
    font-size: calc(2 * var(--rv));
  }
  .p-m-index-review__point {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-review__comment {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-review__name01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-review__time {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-review__reviewer img {
    width: calc(14.2 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-review__player img {
    width: calc(14.7 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-review__h3 {
    margin-top: calc(-3 * var(--rv));
    margin-bottom: calc(4 * var(--rv));
  }
  .p-m-index-review__p01 {
    margin-bottom: calc(1.5 * var(--rv));
    padding: 0 calc(5 * var(--rv));
    line-height: 1.7;
  }
  .p-m-index-review__bottom {
    display: block;
  }
  .p-m-index-review__span {
    margin-top: calc(5.2 * var(--rv));
    width: calc(11.5 * var(--rv));
  }
  .p-m-index-review__name02 {
    margin-top: calc(0.5 * var(--rv));
  }
  .p-m-index-review__point {
    margin-left: calc(1 * var(--rv));
  }
  .p-m-index-review__comment {
    letter-spacing: calc(-0.2 * var(--rv));
    line-height: 1.6;
    margin-bottom: calc(3 * var(--rv));
  }
  .p-m-index-review__time {
    margin-left: 0;
  }
  .p-m-index-review__reviewer {
    margin-right: calc(2 * var(--rv));
  }
  .p-m-index-review__card {
    margin-top: calc(1.3 * var(--rv));
  }
  .p-m-index-review__span::after {
    top: calc(3.8 * var(--rv));
  }
  .p-m-index-review__star img {
    width: calc(11.6 * var(--rv));
    height: calc(2.5 * var(--rv));
  }
  .p-m-index-review__reviewer::after {
    top: calc(-0.8 * var(--rv));
    left: calc(-0.8 * var(--rv));
    width: calc(15.8 * var(--rv));
    height: calc(15.9 * var(--rv));
  }
  .p-m-index-review__player::before {
    top: calc(-0.7 * var(--rv));
    left: calc(-0.7 * var(--rv));
    width: calc(16.1 * var(--rv));
    height: calc(16.1 * var(--rv));
  }
  .p-m-index-review__name01 {
    text-align: center;
    position: absolute;
    top: calc(15 * var(--rv));
    left: calc(-17.5 * var(--rv));
    width: calc(15.8 * var(--rv));
  }
  .p-m-index-review__star {
    align-items: center;
  }
  .p-m-index-review__container {
    width: calc(40 * var(--rv));
  }
  .p-m-index-review__h3 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-review__span {
    font-size: calc(2.1 * var(--rv));
  }
  .p-m-index-review__name02 {
    font-size: calc(1.8 * var(--rv));
  }
}

.c-m-card--review {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: flex;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: flex-start;
  padding: calc(4 * var(--rv));
  padding-bottom: calc(2 * var(--rv));
}

.c-m-card--review-block:nth-child(1) {
  flex: 1;
}
.c-m-card--review-block:nth-child(2) {
  flex: 8;
}
.c-m-card--review-block:nth-child(3) {
  flex: 2;
}
.c-m-card--review-block:nth-child(4) {
  flex: 1;
}

@media (max-width: 750px) {
  .c-m-card--review {
    flex-wrap: wrap;
    justify-content: flex-end;
    padding-bottom: calc(3 * var(--rv));
  }
  .c-m-card--review-block:nth-child(1) {
    flex: unset;
  }
  .c-m-card--review-block:nth-child(2) {
    flex: unset;
  }
  .c-m-card--review-block:nth-child(3) {
    flex: unset;
  }
  .c-m-card--review-block:nth-child(4) {
    flex: unset;
  }
}