.p-m-index-faq {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-faq__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-faq__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-faq__head {
  display: flex;
}
.p-m-index-faq__head img {
  width: calc(35.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-faq__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-faq__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Medium", "Noto Sans JP", sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 500;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-faq__label {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-faq__q {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(2.362 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-faq {
  padding-top: calc(6 * var(--rv));
  padding-bottom: calc(10.5 * var(--rv));
}
.p-m-index-faq__top {
  align-items: center;
}
.p-m-index-faq__h3 {
  margin-top: calc(-14 * var(--rv));
  margin-bottom: calc(7.5 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-faq__bottom {
  padding-left: calc(5 * var(--rv));
  padding-right: calc(5 * var(--rv));
}
.p-m-index-faq__p01 {
  line-height: 1.75;
}
.p-m-index-faq__accordion {
  margin-bottom: calc(1.7 * var(--rv));
}
.p-m-index-faq__q {
  margin-left: calc(1.5 * var(--rv));
  margin-right: calc(0.5 * var(--rv));
}
.p-m-index-faq input[id^=q] {
  display: none;
}


@media (max-width: 750px) {
  .p-m-index-faq {
    position: relative;
  }
  .p-m-index-faq__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-faq__head img {
    width: calc(28.6 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-faq__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-faq__label {
    font-size: calc(3.6 * var(--rv));
    padding-right: calc(6 * var(--rv));
  }
  .p-m-index-faq__q {
    font-size: calc(3.6 * var(--rv));
  }
  .p-m-index-faq {
    padding-top: calc(2 * var(--rv));
  }
  .p-m-index-faq__h3 {
    margin-top: calc(-8 * var(--rv));
    margin-bottom: calc(5.5 * var(--rv));
  }
  .p-m-index-faq__accordion {
    margin-bottom: calc(5.5 * var(--rv));
  }
  .p-m-index-faq__p01 {
    letter-spacing: calc(-0.25 * var(--rv));
  }
  .p-m-index-faq__h3 {
    font-size: calc(5 * var(--rv));
  }
}
