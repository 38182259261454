.p-m-index-voice {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-voice__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-voice__h3 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-voice__head {
  display: flex;
}
.p-m-index-voice__head img {
  width: calc(55.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__center {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-voice__p01 {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-voice__p02 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(2.4 * var(--rv));
  font-weight: 900;
  line-height: 2.1;
  white-space: pre-line;
}
.p-m-index-voice__h4 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(3.412 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-voice__h5 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-voice__illust01 {
  display: flex;
}
.p-m-index-voice__illust01 img {
  width: calc(34 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__balloon {
  display: flex;
}
.p-m-index-voice__balloon img {
  width: calc(55.2 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__icon03 {
  display: flex;
}
.p-m-index-voice__icon03 img {
  width: calc(18.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__icon01 {
  display: flex;
}
.p-m-index-voice__icon01 img {
  width: calc(20.8 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__icon02 {
  display: flex;
}
.p-m-index-voice__icon02 img {
  width: calc(22.1 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__pop03 {
  display: flex;
}
.p-m-index-voice__pop03 img {
  width: calc(19.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__pop02 {
  display: flex;
}
.p-m-index-voice__pop02 img {
  width: calc(19.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__pop01 {
  display: flex;
}
.p-m-index-voice__pop01 img {
  width: calc(19.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-voice__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-voice__button {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(3.78 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-voice {
  padding-top: calc(8 * var(--rv));
  padding-bottom: calc(6 * var(--rv));
  overflow: hidden;
  background-image: url(../../images/top/voice_back@2x.webp);
  background-position: center;
  background-repeat: no-repeat;
  background-size: calc(84.7 * var(--rv));
}
.p-m-index-voice__top {
  align-items: center;
}
.p-m-index-voice__h3 {
  margin-top: calc(-10 * var(--rv));
  margin-bottom: calc(14 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-voice__center {
  align-items: center;
}
.p-m-index-voice__p01 {
  text-align: center;
  margin-bottom: calc(5 * var(--rv));
  line-height: 2.2;
}
.p-m-index-voice__bottom {
  align-items: center;
}
.p-m-index-voice__button {
  font-size: calc(2.9 * var(--rv));
  width: calc(54 * var(--rv));
  height: calc(7.5 * var(--rv));
}
.p-m-index-voice__p02 {
  text-align: center;
  margin-bottom: calc(12 * var(--rv));
}
.p-m-index-voice__h4 strong {
  font-size: calc(4.4 * var(--rv));
}
.p-m-index-voice__h5 {
  margin-top: calc(3 * var(--rv));
  margin-bottom: calc(6 * var(--rv));
}
.p-m-index-voice__h5 strong {
  font-size: calc(5.4 * var(--rv));
}
.p-m-index-voice__h5 strong span {
  position: relative;
}
.p-m-index-voice__h5 strong span::after {
  background-color: #e82598;
  border-radius: 100%;
  content: '';
  position: absolute;
  top: calc(-0.3 * var(--rv));
  left: calc(2 * var(--rv));
  width: calc(1 * var(--rv));
  height: calc(1 * var(--rv));
}
.p-m-index-voice__illust01 {
  position: absolute;
  top: calc(9 * var(--rv));
  right: calc(-1.5 * var(--rv));
}
.p-m-index-voice__balloon {
  margin-top: calc(-11 * var(--rv));
  margin-bottom: calc(7 * var(--rv));
}
.p-m-index-voice__icon03 {
  position: absolute;
  top: calc(44.5 * var(--rv));
  left: calc(2.5 * var(--rv));
}
.p-m-index-voice__icon01 {
  position: absolute;
  top: calc(-3 * var(--rv));
  left: 0;
}
.p-m-index-voice__icon02 {
  position: absolute;
  top: calc(20 * var(--rv));
  left: calc(7 * var(--rv));
}
.p-m-index-voice__pop02 {
  margin: 0 calc(4 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-voice {
    position: relative;
  }
  .p-m-index-voice__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-voice__head img {
    width: calc(44.9 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-voice__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-voice__h4 {
    font-size: calc(3.9 * var(--rv));
  }
  .p-m-index-voice__h5 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-voice__illust01 img {
    width: calc(34 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__balloon img {
    width: calc(63.1 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__icon03 img {
    width: calc(18.9 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__icon01 img {
    width: calc(21.5 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__icon02 img {
    width: calc(22.8 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__pop03 img {
    width: calc(20.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__pop02 img {
    width: calc(20.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__pop01 img {
    width: calc(20.4 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-voice__button {
    font-size: calc(4.2 * var(--rv));
  }
  .p-m-index-voice {
    background-image: url(../../images/top/voice_back_sp.webp);
    background-position: 0 calc(60 * var(--rv));
    background-size: contain;
  }
  .p-m-index-voice__h3 {
    margin-top: calc(-4 * var(--rv));
    margin-bottom: calc(33.5 * var(--rv));
  }
  .p-m-index-voice__p01 {
    line-height: 1.9;
  }
  .p-m-index-voice__p02 {
    margin-bottom: calc(57 * var(--rv));
    font-size: calc(3.3 * var(--rv));
    line-height: 1.8;
  }
  .p-m-index-voice__h5 {
    margin-top: calc(4 * var(--rv));
    margin-bottom: calc(5 * var(--rv));
    text-align: center;
  }
  .p-m-index-voice__illust01 {
    top: calc(64 * var(--rv));
    right: calc(21 * var(--rv));
  }
  .p-m-index-voice__balloon {
    margin-bottom: calc(3 * var(--rv));
  }
  .p-m-index-voice__icon03 {
    top: calc(-25.5 * var(--rv));
    left: calc(52 * var(--rv));
  }
  .p-m-index-voice__icon01 {
    top: calc(-25.5 * var(--rv));
    left: calc(4 * var(--rv));
  }
  .p-m-index-voice__icon02 {
    top: calc(-29 * var(--rv));
    left: calc(26 * var(--rv));
  }
  .p-m-index-voice__button {
    width: calc(66 * var(--rv));
    height: calc(9 * var(--rv));
  }
  .p-m-index-voice__h4 strong {
    font-size: calc(4.9 * var(--rv));
  }
  .p-m-index-voice__h5 strong {
    font-size: calc(6.4 * var(--rv));
  }
  .p-m-index-voice__h5 strong span::after {
    left: calc(2.5 * var(--rv));
  }
  .p-m-index-voice__h3 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-voice__button {
    font-size: calc(3.2 * var(--rv));
  }
}
