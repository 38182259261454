.p-m-index-mv {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}

.p-m-index-mv__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}

.p-m-index-mv__h2 {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(3.717 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-mv__h3 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(9.725 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
  z-index: 1;
}

.p-m-index-mv__span {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(2.5 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-mv__p01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(3.831 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-mv__p02 {
  color: #e82598;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.9 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-mv__p02 .aux01 {
  color: #333333;
  font-family: "NotoSansCJKjp-Black", "Noto Sans JP", sans-serif;
  font-size: calc(4.9 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-mv__button {
  color: #ffffff;
  font-family: "NotoSansCJKjp-Bold", "Noto Sans JP", sans-serif;
  font-size: calc(4.2 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}

.p-m-index-mv__illust {
  display: flex;
}

.p-m-index-mv__illust img {
  width: calc(63.4 * var(--rv));
  height: auto;
  object-fit: contain;
}

.p-m-index-mv__logo {
  display: flex;
}

.p-m-index-mv__logo img {
  width: calc(26.1 * var(--rv));
  height: auto;
  object-fit: contain;
}

.p-m-index-mv__image01 {
  display: flex;
}

.p-m-index-mv__image01 img {
  width: calc(7.4 * var(--rv));
  height: auto;
  object-fit: contain;
}

.p-m-index-mv__image02 {
  display: flex;
}

.p-m-index-mv__image02 img {
  width: calc(5.6 * var(--rv));
  height: auto;
  object-fit: contain;
}

.p-m-index-mv__balloon {
  display: flex;
}

.p-m-index-mv__balloon img {
  width: calc(28.5 * var(--rv));
  height: auto;
  object-fit: contain;
}

/* Add */
.p-m-index-mv {
  padding-top: calc(3 * var(--rv));
  padding-bottom: calc(8 * var(--rv));
  overflow: hidden;
}

.p-m-index-mv__top {
  align-items: center;
  max-width: 60%;
}

.p-m-index-mv__h2 {
  margin-bottom: calc(3.5 * var(--rv));
  font-size: calc(2.7 * var(--rv));
}

.p-m-index-mv__h3 {
  letter-spacing: calc(-0.5 * var(--rv));
  text-align: center;
  margin-bottom: calc(5.5 * var(--rv));
  font-size: calc(7.5 * var(--rv));
  line-height: 1.1;
}

.p-m-index-mv__p01 {
  font-size: calc(3.2 * var(--rv));
}

.p-m-index-mv__p02 {
  text-align: center;
  margin-bottom: calc(2 * var(--rv));
  font-size: calc(4.2 * var(--rv));
  line-height: 1.3;
}

.p-m-index-mv__p02 .aux01 {
  font-size: calc(3.6 * var(--rv));
}

.p-m-index-mv__button {
  font-size: calc(3.2 * var(--rv));
  width: calc(48 * var(--rv));
  height: calc(7.6 * var(--rv));
}

.p-m-index-mv__h3 strong {
  margin-right: calc(1 * var(--rv));
  font-size: calc(9.7 * var(--rv));
}

.p-m-index-mv__illust {
  position: absolute;
  top: calc(2 * var(--rv));
  right: calc(-55 * var(--rv));
}

.p-m-index-mv__logo {
  margin-bottom: calc(4 * var(--rv));
}

.p-m-index-mv__image01 {
  position: absolute;
  top: calc(30 * var(--rv));
  left: calc(2.5 * var(--rv));
}

.p-m-index-mv__image02 {
  position: absolute;
  top: calc(2 * var(--rv));
  left: calc(56 * var(--rv));
}

.p-m-index-mv__balloon {
  margin-top: calc(-4 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-mv {
    position: relative;
  }

  .p-m-index-mv__h2 {
    font-size: calc(3.823 * var(--rv));
  }

  .p-m-index-mv__h3 {
    font-size: calc(10.213 * var(--rv));
  }

  .p-m-index-mv__p01 {
    font-size: calc(5.28 * var(--rv));
  }

  .p-m-index-mv__p02 {
    font-size: calc(4.7 * var(--rv));
  }

  .p-m-index-mv__p02 .aux01 {
    font-size: calc(4.7 * var(--rv));
  }

  .p-m-index-mv__button {
    font-size: calc(5.5 * var(--rv));
  }

  .p-m-index-mv__illust img {
    width: calc(57.2 * var(--rv));
    height: auto;
    object-fit: contain;
  }

  .p-m-index-mv__logo img {
    width: calc(19.3 * var(--rv));
    height: auto;
    object-fit: contain;
  }

  .p-m-index-mv__image01 img {
    width: calc(8.5 * var(--rv));
    height: auto;
    object-fit: contain;
  }

  .p-m-index-mv__image02 img {
    width: calc(6.5 * var(--rv));
    height: auto;
    object-fit: contain;
  }

  .p-m-index-mv__balloon img {
    width: calc(39.2 * var(--rv));
    height: auto;
    object-fit: contain;
  }

  /* Add */
  .p-m-index-mv {
    padding-top: calc(2 * var(--rv));
    padding-bottom: calc(5 * var(--rv));
  }

  .p-m-index-mv__top {
    max-width: 100%;
  }

  .p-m-index-mv__h3 {
    letter-spacing: calc(-0.6 * var(--rv));
    margin-bottom: calc(64.5 * var(--rv));
    font-size: calc(7.5 * var(--rv));
  }

  .p-m-index-mv__p02 {
    margin-bottom: calc(3 * var(--rv));
  }

  .p-m-index-mv__button {
    width: calc(66 * var(--rv));
    height: calc(9.6 * var(--rv));
  }

  .p-m-index-mv__illust {
    top: calc(28 * var(--rv));
    right: calc(8 * var(--rv));
  }

  .p-m-index-mv__image01 {
    left: calc(3 * var(--rv));
  }

  .p-m-index-mv__image02 {
    top: calc(4 * var(--rv));
    left: calc(65.5 * var(--rv));
  }

  .p-m-index-mv__balloon {
    margin-top: calc(-6 * var(--rv));
    margin-bottom: calc(1.5 * var(--rv));
  }

  .p-m-index-mv__logo {
    position: absolute;
    left: calc(4.5 * var(--rv));
    top: calc(42.5 * var(--rv));
    z-index: 1;
  }

  .p-m-index-mv__h2 {
    font-size: calc(2.75 * var(--rv));
  }

  .p-m-index-mv__h3 {
    font-size: calc(8 * var(--rv));
  }

  .p-m-index-mv__p01 {
    font-size: calc(4.28 * var(--rv));
  }

  .p-m-index-mv__p02 {
    font-size: calc(3.5 * var(--rv));
  }

  .p-m-index-mv__p02 .aux01 {
    font-size: calc(3.5 * var(--rv));
  }

  .p-m-index-mv__button {
    font-size: calc(4.2 * var(--rv));
  }
}
