
.swiper {
  width: 100%;
}

.swiper-wrapper {
  flex-wrap: wrap;
  width: calc(100% - calc(8 * var(--rv)));
  height: calc(110 * var(--rv));
  padding-left: calc(5 * var(--rv));
  padding-right: calc(3 * var(--rv));
  overflow-y: scroll;
}

/* Chrome */

.swiper-wrapper::-webkit-scrollbar {
  width: calc(2 * var(--rv));
}

.swiper-wrapper::-webkit-scrollbar-track {
  background: #ececec;
  border-radius: calc(1 * var(--rv));
}

.swiper-wrapper::-webkit-scrollbar-thumb {
  background: #ee88c5;
  border-radius: calc(1 * var(--rv));
}

/* Firefox */

.swiper-wrapper {
  scrollbar-width: auto;
  scrollbar-color: #ee88c5 #ececec;
}

.swiper-slide {
  height: auto;
  width: 100%!important;
}

.swiper-button-prev {
  display: none;
}

.swiper-button-next {
  display: none;
}

@media (max-width: 750px) {
  .swiper-wrapper {
    flex-wrap: nowrap;
    width: 100%;
    height: auto;
    padding: 0;
    overflow-y: visible;
  }

  .swiper-slide {
    width: calc(100vw - calc(10 * var(--rv)))!important;
    margin: 0 calc(5 * var(--rv));
  }

  .swiper-button-prev {
    display: block;
  }

  .swiper-button-next {
    display: block;
  }

  .swiper-button-prev::after {
    border-left: calc(0.8 * var(--rv)) solid #e82598;
    border-bottom: calc(0.8 * var(--rv)) solid #e82598;
    content: '';
    position: absolute;
    top: calc(50% - calc(1 * var(--rv)));
    left: calc(1.8 * var(--rv));
    transform: rotate(45deg);
    transition: top 0.5s, transform 0.5s;
    width: calc(2.5 * var(--rv));
    height: calc(2.5 * var(--rv));
  }

  .swiper-button-next::after {
    border-left: calc(0.8 * var(--rv)) solid #e82598;
    border-bottom: calc(0.8 * var(--rv)) solid #e82598;
    content: '';
    position: absolute;
    top: calc(50% - calc(1 * var(--rv)));
    right: calc(1.8 * var(--rv));
    transform: rotate(-135deg);
    transition: top 0.5s, transform 0.5s;
    width: calc(2.5 * var(--rv));
    height: calc(2.5 * var(--rv));
  }
}

