.p-m-index-player {
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
}
.p-m-index-player__top {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-player__h3 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.025 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__p01 {
  color: #ea3da1;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__p01 .aux01 {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(2.1 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__head {
  display: flex;
}
.p-m-index-player__head img {
  width: calc(69.5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-player__bottom {
  display: flex;
  flex: 0 0 100%;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
  max-width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.p-m-index-player__point {
  color: #333333;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__point .aux01 {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__comment {
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.488 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__li {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(2.45 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__prev {
  color: #cccccc;
  font-family: 'NotoSansCJKjp-Regular', 'Noto Sans JP', sans-serif;
  font-size: calc(1.75 * var(--rv));
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__next {
  color: #333333;
  font-family: 'NotoSansCJKjp-Regular', 'Noto Sans JP', sans-serif;
  font-size: calc(1.75 * var(--rv));
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__p02 {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(4.375 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__span {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Black', 'Noto Sans JP', sans-serif;
  font-size: calc(8.245 * var(--rv));
  font-weight: 900;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__button {
  color: #e82598;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(3.78 * var(--rv));
  font-weight: 700;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__current {
  display: flex;
}
.p-m-index-player__current img {
  width: calc(4.5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-player__image {
  display: flex;
}
.p-m-index-player__image img {
  width: calc(18.9 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-player {
  padding-top: calc(6 * var(--rv));
  padding-bottom: calc(6 * var(--rv));
}
.p-m-index-player__top {
  align-items: center;
}
.p-m-index-player__h3 {
  margin-top: calc(-9 * var(--rv));
  margin-bottom: calc(6 * var(--rv));
  font-size: calc(3.8 * var(--rv));
}
.p-m-index-player__bottom {
  padding-left: calc(5 * var(--rv));
  padding-right: calc(5 * var(--rv));
}
.p-m-index-player__comment {
  letter-spacing: calc(0.02 * var(--rv));
  line-height: 1.7;
}
.p-m-index-player__next {
  margin: 0 calc(2 * var(--rv));
  color: #cccccc;
}
.p-m-index-player__p02 {
  letter-spacing: calc(-0.3 * var(--rv));
  margin-top: calc(2 * var(--rv));
  margin-left: calc(2 * var(--rv));
  line-height: 1.2;
  max-width: calc(64 * var(--rv));
}
.p-m-index-player__button {
  margin-top: calc(3 * var(--rv));
  margin-bottom: calc(1 * var(--rv));
  font-size: calc(2.8 * var(--rv));
  width: calc(46 * var(--rv));
  height: calc(8 * var(--rv));
}
.p-m-index-player__p01 {
  margin-bottom: calc(4.8 * var(--rv));
}
.p-m-index-player__figcaption {
  text-align: center;
  margin-top: calc(1.6 * var(--rv));
  margin-bottom: calc(1.2 * var(--rv));
  color: #333333;
  font-family: 'NotoSansCJKjp-Bold', 'Noto Sans JP', sans-serif;
  font-size: calc(1.5 * var(--rv));
  font-weight: 400;
  line-height: 1.5;
  white-space: pre-line;
}
.p-m-index-player__player {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: calc(3.3 * var(--rv));
  z-index: 1;
  width: calc(19.3 * var(--rv));
}
.p-m-index-player__player > div {
  width: 100%;
}
.p-m-index-player__player img {
  width: calc(17.3 * var(--rv));
  height: auto;
  object-fit: contain;
}
/* .p-m-index-player__player::before {
  background: linear-gradient(135deg, rgb(222, 237, 248) 0%, rgb(252, 226, 243) 100%);
  border-radius: 100%;
  content: "";
  position: absolute;
  top: calc(-0.6 * var(--rv));
  left: calc(0.4 * var(--rv));
  z-index: -1;
  width: calc(18.5 * var(--rv));
  height: calc(18.5 * var(--rv));
} */
.p-m-index-player__player.is-m-dummy {
  margin: 0;
  height: 0;
}
.p-m-index-player__player.is-m-dummy::before {
  display: none;
}
.p-m-index-player__play {
  display: flex;
  position: absolute;
  top: calc(12.5 * var(--rv));
  right: 0;
}
.p-m-index-player__play img {
  width: calc(5 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-player__star {
  display: flex;
  flex-direction: row;
  max-width: calc(17.3 * var(--rv));
  margin: 0 calc(0.5 * var(--rv));
  z-index: 1;
}
.p-m-index-player__star img {
  border-radius: 0;
  width: calc(8.4 * var(--rv));
  height: auto;
  object-fit: contain;
}
.p-m-index-player__point {
  margin-left: calc(0.5 * var(--rv));
}
.p-m-index-player__prev {
  margin: 0 calc(2 * var(--rv));
}
.p-m-index-player__prev a {
  text-decoration: none;
  color: #333333;
}
.p-m-index-player__next a {
  text-decoration: none;
  color: #333333;
}
.p-m-index-player__span {
  display: inline-block;
  transform: rotate(-10deg);
  left: calc(4 * var(--rv));
  top: calc(-0.5 * var(--rv));
}
.p-m-index-player__image {
  margin-right: calc(2 * var(--rv));
}

@media (max-width: 750px) {
  .p-m-index-player {
    position: relative;
  }
  .p-m-index-player__h3 {
    font-size: calc(5.5 * var(--rv));
  }
  .p-m-index-player__p01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-player__p01 .aux01 {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-player__head img {
    width: calc(56.2 * var(--rv));
    height: auto;
    object-fit: contain;
  }
  .p-m-index-player__point {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-player__point .aux01 {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-player__comment {
    font-size: calc(2.4 * var(--rv));
  }
  .p-m-index-player__li {
    font-size: calc(3 * var(--rv));
  }
  .p-m-index-player__prev {
    font-size: calc(3.282 * var(--rv));
  }
  .p-m-index-player__next {
    font-size: calc(3.282 * var(--rv));
  }
  .p-m-index-player__p02 {
    font-size: calc(2.967 * var(--rv));
  }
  .p-m-index-player__span {
    font-size: calc(5.591 * var(--rv));
  }
  .p-m-index-player__button {
    font-size: calc(4.2 * var(--rv));
  }
  .p-m-index-player__h3 {
    margin-top: calc(-4 * var(--rv));
    margin-bottom: calc(4 * var(--rv));
  }
  .p-m-index-player__p01 {
    margin-bottom: calc(3.8 * var(--rv));
    padding: 0 calc(3.5 * var(--rv));
  }
  .p-m-index-player__p01 .aux01 {
    line-height: 1.8;
  }
  .p-m-index-player__point {
    margin-left: calc(1 * var(--rv));
  }
  .p-m-index-player__li {
    line-height: 1.3;
  }
  .p-m-index-player__p02 {
    margin-top: calc(4 * var(--rv));
    margin-left: calc(1 * var(--rv));
    max-width: calc(42 * var(--rv));
  }
  .p-m-index-player__button {
    width: calc(58 * var(--rv));
    height: calc(10 * var(--rv));
  }
  .p-m-index-player__image {
    margin-right: calc(1 * var(--rv));
  }
  .p-m-index-player__image img {
    width: calc(14.9 * var(--rv));
    height: calc(20.5 * var(--rv));
  }
  .p-m-index-player__figcaption {
    font-size: calc(2.1 * var(--rv));
  }
  .p-m-index-player__player {
    margin-bottom: calc(4.3 * var(--rv));
    width: calc(27.6 * var(--rv));
  }
  .p-m-index-player__player img {
    width: calc(25.6 * var(--rv));
    height: calc(25.6 * var(--rv));
  }
  .p-m-index-player__player::before {
    top: calc(-1 * var(--rv));
    left: 0;
    width: calc(27.6 * var(--rv));
    height: calc(27.6 * var(--rv));
  }
  .p-m-index-player__play {
    top: calc(18 * var(--rv));
    right: calc(1 * var(--rv));
  }
  .p-m-index-player__play img {
    width: calc(7 * var(--rv));
    height: calc(7 * var(--rv));
  }
  .p-m-index-player__star {
    max-width: calc(27.6 * var(--rv));
    margin: 0 calc(1 * var(--rv));
    justify-content: flex-start;
    align-items: center;
  }
  .p-m-index-player__star img {
    width: calc(11.6 * var(--rv));
    height: calc(2.4 * var(--rv));
  }
  .p-m-index-player__prev {
    margin-left: 0;
  }
  .p-m-index-player__next {
    margin-right: 0;
  }
  .p-m-index-player__h3 {
    font-size: calc(5 * var(--rv));
  }
  .p-m-index-player__button {
    font-size: calc(3.2 * var(--rv));
  }
}

.c-m-tabs {
  width: 100%;
  position: relative;
}
.c-m-tabs__list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
}
.c-m-tabs__page {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-start;
}
.c-m-tabs__below {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  padding: calc(1 * var(--rv));
}

.c-m-tabs input {
  display: none;
}

.c-m-tabs #tab_ctl01:checked ~ ul #tab01 {
  background-color: #e82598;
  color: #ffffff;
}

.c-m-tabs #tab_ctl01:checked ~ ul #tab01::after {
  background-image: url(../../images/top/player_current@2x.webp);
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  content: '';
  position: absolute;
  bottom: calc(-1.5 * var(--rv));
  left: calc(50% - calc(1 * var(--rv)));
  width: calc(2 * var(--rv));
  height: calc(1.5 * var(--rv));
}

.c-m-tabs #tab_ctl02:checked ~ ul #tab02 {
  background-color: #e82598;
  color: #ffffff;
}

.c-m-tabs #tab_ctl02:checked ~ ul #tab02::after {
  background-image: url(../../images/top/player_current@2x.webp);
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  content: '';
  position: absolute;
  bottom: calc(-1.5 * var(--rv));
  left: calc(50% - calc(1 * var(--rv)));
  width: calc(2 * var(--rv));
  height: calc(1.5 * var(--rv));
}

.c-m-tabs #tab_ctl03:checked ~ ul #tab03 {
  background-color: #e82598;
  color: #ffffff;
}

.c-m-tabs #tab_ctl03:checked ~ ul #tab03::after {
  background-image: url(../../images/top/player_current@2x.webp);
  background-position: center;
  background-repeat: repeat-y;
  background-size: cover;
  content: '';
  position: absolute;
  bottom: calc(-1.5 * var(--rv));
  left: calc(50% - calc(1 * var(--rv)));
  width: calc(2 * var(--rv));
  height: calc(1.5 * var(--rv));
}

.c-m-tabs > ul > li {
  background-color: #ffffff;
  border: calc(0.4 * var(--rv)) solid #e82598;
  border-radius: calc(4 * var(--rv));
  white-space: normal;
  width: calc(31.5 * var(--rv));
  height: calc(6.5 * var(--rv));
}

.c-m-tabs li label {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

.c-m-tabs #tab_box01 {
  display: none;
}

.c-m-tabs #tab_ctl01:checked ~ #tab_box01 {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: block;
  margin-top: calc(4.5 * var(--rv));
  padding: calc(5 * var(--rv)) calc(4 * var(--rv));
  width: calc(100% - calc(8 * var(--rv)));
  padding-bottom: 400px;
}

.c-m-tabs #tab_box02 {
  display: none;
}

.c-m-tabs #tab_ctl02:checked ~ #tab_box02 {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: block;
  margin-top: calc(4.5 * var(--rv));
  padding: calc(5 * var(--rv)) calc(4 * var(--rv));
  width: calc(100% - calc(8 * var(--rv)));
  padding-bottom: 400px;
}

.c-m-tabs #tab_box03 {
  display: none;
}

.c-m-tabs #tab_ctl03:checked ~ #tab_box03 {
  border-radius: calc(2.2 * var(--rv));
  box-shadow: 0 0 calc(1.8 * var(--rv)) rgba(218, 196, 205, 0.5);
  display: block;
  margin-top: calc(4.5 * var(--rv));
  padding: calc(5 * var(--rv)) calc(4 * var(--rv));
  width: calc(100% - calc(8 * var(--rv)));
  padding-bottom: 400px;
}

@media (max-width: 750px) {
  .c-m-tabs__below {
    padding: 0 calc(1 * var(--rv));
  }
  .c-m-tabs > ul > li {
    border-radius: calc(5 * var(--rv));
    text-align: center;
    width: calc(21 * var(--rv));
    height: calc(10 * var(--rv));
  }
  .c-m-tabs #tab_ctl01:checked ~ ul #tab01::after {
    bottom: calc(-2 * var(--rv));
    left: calc(50% - calc(2 * var(--rv)));
    width: calc(4 * var(--rv));
    height: calc(3 * var(--rv));
  }
  .c-m-tabs #tab_ctl01:checked ~ #tab_box01 {
    padding: calc(5 * var(--rv)) calc(2 * var(--rv));
    width: calc(100% - calc(4 * var(--rv)));
  }
  .c-m-tabs #tab_ctl02:checked ~ ul #tab02::after {
    bottom: calc(-2 * var(--rv));
    left: calc(50% - calc(2 * var(--rv)));
    width: calc(4 * var(--rv));
    height: calc(3 * var(--rv));
  }
  .c-m-tabs #tab_ctl02:checked ~ #tab_box02 {
    padding: calc(5 * var(--rv)) calc(2 * var(--rv));
    width: calc(100% - calc(4 * var(--rv)));
  }
  .c-m-tabs #tab_ctl03:checked ~ ul #tab03::after {
    bottom: calc(-2 * var(--rv));
    left: calc(50% - calc(2 * var(--rv)));
    width: calc(4 * var(--rv));
    height: calc(3 * var(--rv));
  }
  .c-m-tabs #tab_ctl03:checked ~ #tab_box03 {
    padding: calc(5 * var(--rv)) calc(2 * var(--rv));
    width: calc(100% - calc(4 * var(--rv)));
  }
}

@media (max-width: 750px) {
  .c-m-tabs #tab_ctl01:checked ~ #tab_box01,
  .c-m-tabs #tab_ctl02:checked ~ #tab_box02,
  .c-m-tabs #tab_ctl03:checked ~ #tab_box03 {
    padding-bottom: 300px;
  }
}

@media (max-width: 540px) {
  .c-m-tabs #tab_ctl01:checked ~ #tab_box01,
  .c-m-tabs #tab_ctl02:checked ~ #tab_box02,
  .c-m-tabs #tab_ctl03:checked ~ #tab_box03 {
    padding-bottom: 250px;
  }
}
